.embed {
  --embed-primary-color: #0086d4;
}
.embed button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}
.embed {
  // position: relative;
  color: #fff;
  background: #000;
}
.embed-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  z-index: 1;
}
.embed video {
  width: 100%;
  height: 100%;
}
.embed-time-indicator {
  font-size: 18px;
  white-space: nowrap;
}
.embed-time-indicator {
  @media screen and (max-width: 1023.98px) {
    font-size: 16px;
  }
}
@media screen and (max-width: 767.98px) {
  .embed-time-indicator {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .embed-time-indicator {
    font-size: 12px;
  }
}
.embed-progress {
  transform-origin: center;
  animation: embed-rotate 2s linear infinite;
}
.embed-progress circle {
  fill: none;
  stroke: #0086d4;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  animation: embed-dash 1.5s ease-in-out infinite;
}
@keyframes embed-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes embed-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
.embed-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}
.embed-upload-subtitle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  z-index: 6;
}
.embed-show {
  opacity: 100% !important;
  visibility: visible !important;
}
.embed-hidden {
  display: none;
}
.embed-icon-lg {
  width: 48px;
  height: 48px;
}
.buttonPlay button svg {
  width: 52px;
  height: 52px;
}
@media screen and (max-width: 767.98px) {
  .embed-icon-lg {
    width: 24px;
    height: 24px;
  }
  .playerControlsContainer .playerControlsInnerContainer svg {
    width: 36px;
    height: 36px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  html body .embed-icon-lg {
    width: 38px;
    height: 38px;
  }
  .playerControlsContainer .playerControlsInnerContainer svg {
    width: 48px;
    height: 48px;
  }
  html body .embed .embed-controls {
    height: 90px;
  }
  html body .embed-controls-section {
    gap: min(4vw, 24px);
  }
  html .embed [data-embed-tooltips]::before {
    display: none;
  }
}
.embed-icon-md {
  width: 24px;
  height: 24px;
  @media screen and (max-width: 767.98px) {
    width: 20px;
    height: 20px;
  }
}
.embed-icon-xs {
  width: 18px;
  height: 18px;
}
.embed-chevron {
  width: 15px;
  height: 15px;
}
.embed-center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.embed [data-embed-tooltips] {
  position: relative;
}
.embed [data-embed-tooltips]::before {
  position: absolute;
  content: attr(data-embed-tooltips);
  opacity: 0;
  transition: 0.15s;
  top: -50px;
  background: #000000b6;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  border-radius: 4px;
  pointer-events: none;
  white-space: nowrap;
  width: max-content;
  @media screen and (max-width: 767.98px) {
    display: none;
  }
}
.embed [data-embed-tooltips]:hover::before {
  opacity: 1;
}
.embed-tooltips-left::before {
  transform: none !important;
  left: -4px !important;
}
.embed-tooltips-right::before {
  transform: none !important;
  left: auto !important;
  right: -4px !important;
}
.embed-icon-underline {
  position: relative;
}
.embed-icon-underline::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  width: 20px;
  height: 3px;
  background: var(--embed-primary-color);
  border-radius: 6px;
}
.embed-fade-zoom-out {
  opacity: 0;
  animation: embed-fade-zoom-out 0.4s linear;
}
.embed-effect-icon {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000088;
  border-radius: 99999px;
  cursor: default;
}
@media screen and (max-width: 767.98px) {
  .embed-effect-icon {
    height: 60px;
    width: 60px;
    animation: embed-fade-zoom-out 0.3s linear;
  }
}

@keyframes embed-fade-zoom-out {
  from {
    opacity: 1;
    transform: scale(0.3);
  }
  to {
    opacity: 0;
    transform: scale(1.3);
  }
}
.embed {
  --control-spacing: min(4vw, 40px);
}
.embed-controls-hovered:hover .embed-controls {
  opacity: 1 !important;
  visibility: visible !important;
  cursor: default;
}
.embed-controls-hovered:hover {
  cursor: pointer;
}
.embed-time {
  white-space: nowrap;
}
.embed-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(to bottom, transparent, #00000091);
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  align-items: stretch;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 10;
}
@media screen and (max-width: 767.98px) {
  .embed-controls {
    height: 70px;
    padding: 0 15px;
  }
}
.embed-controls-main {
  position: relative;
  margin-top: -11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.embed-controls-section {
  display: flex;
  gap: var(--control-spacing);
  align-items: center;
  @media screen and (max-width: 767.98px) {
    gap: min(5vw, 20px);
  }
}
.embed-volume-container {
  display: flex;
  align-items: stretch;
  gap: 6px;
}
.embed-volume-slider {
  height: 5px;
  background: #ffffff91;
  outline: none;
  border-radius: 1px;
  appearance: none;
  width: 52px;
  z-index: 1;
  cursor: pointer;
  margin: 0;
}
.embed-scale {
  transition: all 0.1s linear;
  &:hover {
    transform: scale(1.2);
  }
}
.embed-volume-slider::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  background: #fff;
  cursor: pointer;
  border-radius: 99999px;
  appearance: none;
}
.embed-volume-slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #fff;
  cursor: pointer;
  border-radius: 99999px;
  appearance: none;
}
.embed-volume-wrapper {
  width: 0;
  transition: 0.3s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.embed-volume-container:hover .embed-volume-wrapper {
  width: 52px;
}
@media (max-width: 640px) {
  .embed-volume-container:hover .embed-volume-wrapper {
    width: 0 !important;
  }
}
.embed-volume-left-bar {
  position: absolute;
  left: 0;
  height: 5px;
  background: #fff;
  width: 52px;
  border-radius: 1px;
}
.embed-seek {
  flex-shrink: 0;
  height: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
  z-index: 20;
}
.embed-seek-bar {
  height: 6px;
  border-radius: 4px;
  overflow: hidden;
  background: #73859f80;
  transition: 0.15s;
}
.embed-seek-left {
  height: 100%;
  background: var(--embed-primary-color);
  position: relative;
}
.embed-seek-left:after {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 99999px;
  background: var(--embed-primary-color);
  transition: 0.15s;
}
.embed-seek-preview {
  position: absolute;
  top: -45px;
  background: #000000b6;
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  pointer-events: none;
  font-size: 18px;
  animation: embed-fade-forward 0.2s forwards;
  @media screen and (max-width: 767.98px) {
    font-size: 12px;
  }
}
@keyframes embed-fade-forward {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.embed-settings-dialog {
  border-radius: 8px;
  position: absolute;
  bottom: 110px;
  right: -30px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #0e1315;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  z-index: 40;
}
.embed-settings-dialog-outer {
  position: relative;
  transition: 0.3s;
  z-index: 110;
}
.embed-settings-section {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  max-height: 400px;
  transition: 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
}
.embed-settings-section-header {
  display: flex;
  align-items: center;
  padding: 0 25px;
  gap: 15px;
  cursor: pointer;
  min-height: 58px;
  border-bottom: 1px solid #565552;
}
.embed-settings-py {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
}
@media screen and (max-width: 767.98px), (max-width: 1023.98px) and (orientation: landscape) {
  .embed-settings-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .embed-settings-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    max-height: 80%;
  }
  .embed-settings-section {
    width: 100%;
  }
}
.embed-settings-translate-left {
  transform: translateX(-100%);
}
.embed-settings-translate-center {
  transform: translateX(0);
}
.embed-settings-translate-right {
  transform: translateX(100%);
}
.embed-settings-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  gap: 12px;
  cursor: pointer;
  transition: 0.2s;
  min-height: 60px;
  font-size: 18px;
  border-radius: 4px;
  @media screen and (max-width: 767.98px), (max-width: 1023.98px) and (orientation: landscape) {
    font-size: 16px;
  }
}
.embed-settings-item p {
  margin: 0;
}
.embed-settings-item:hover {
  background: #343434;
}
.embed-settings-item-left {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
}
.embed-settings-item-right {
  display: flex;
  align-items: center;
  gap: 6px;
}
.embed-settings-item-right span {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #e5e7eb;
  display: -webkit-box;
  overflow: hidden;
  text-transform: capitalize;
}
.embed-settings-item-2 {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.2s;
  min-height: 60px;
  font-size: 18px;
  border-radius: 4px;
  @media screen and (max-width: 767.98px), (max-width: 1023.98px) and (orientation: landscape) {
    font-size: 16px;
  }
}
.embed-settings-item-2 p {
  margin: 0;
}
.embed-settings-item-2:hover {
  background: #343434;
}
.embed-settings-item-2-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.embed *::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.embed *::-webkit-scrollbar-track {
  background: transparent;
}
.embed *::-webkit-scrollbar-thumb {
  border: 1px solid #191a1f;
  background: #666;
  border-radius: 16px;
}
.embed *::-webkit-scrollbar-thumb:hover {
  background: #777;
}
.embed *::-webkit-scrollbar-button {
  display: none;
}
.embed-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.embed-modal {
  width: 95vw;
  max-width: 400px;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
  background: #f9f9f9;
  color: #000;
  padding: 20px;
}
.embed-modal h1 {
  margin: 0;
  font-weight: normal;
  font-size: 25px;
}
.embed-modal p {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 2px;
  font-size: 15px;
}
.embed-modal select {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  border: 1px solid #d8d8d8;
  outline: none;
  background: #f1f1f1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.embed-modal option {
  background: #f1f1f1;
  width: 100%;
}
.embed-modal-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.embed-subtitle-container {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 300ms;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.embed-subtitle-container.interacting {
  bottom: 10rem;
}
.embed-subtitle-text {
  width: fit-content;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.125rem;
  line-height: 1.75rem;
  text-align: center;
  padding: 0.25rem 0.5rem;
  white-space: pre-wrap;
}

.overlayContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  visibility: visible;
  opacity: 1;
  transition: all 0.3s linear;
  position: absolute;
  z-index: 5;
}
.overlayContainer.inactive {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.overlayContainer.inactive * {
  pointer-events: none;
}
.dragMessage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;
  visibility: visible;
  opacity: 1;
  transition: visibility 300ms, opacity 300ms;
}
.dragMessageIcon {
  width: 2rem;
  height: 2rem;
}
.inactive {
  visibility: hidden;
  opacity: 0;
}
.playerControlsContainer {
  position: absolute;
  top: 45%;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.playerControlsInnerContainer {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  pointer-events: none;
}
.playerControlsInnerContainer > * {
  pointer-events: auto;
}
:global(.control-button) {
  color: white;
}
.backwardButton button,
.backwardButton svg {
  width: 40px;
  height: 40px;
}
.playButton button,
.playButton svg {
  width: 44px;
  height: 44px;
}
.forwardButton button,
.forwardButton svg {
  width: 40px;
  height: 40px;
}
.mobileTopButtons {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.mobileTopButtons :global(.control-button) {
  width: 2rem;
  height: 2rem;
}
