.PopoverContent {
  width: var(--radix-popper-available-width);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 100;
  height: 60vh;
  max-height: 450px;
  max-width: 400px;
}
@media screen and (max-width: 1023.98px) {
  .PopoverContent {
    max-height: 100vh;
    max-width: 100%;
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  body .PopoverContent {
    width: 350px;
    height: 100vh;
  }
}
.PopoverContent[data-state="open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.PopoverContent[data-state="open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.PopoverContent[data-state="open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.PopoverContent[data-state="open"][data-side="left"] {
  animation-name: slideRightAndFade;
}
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
